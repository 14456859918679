import ServiceApi from '../ServiceApi';

export default class ServiceReconciliationImport {
    static _microserviceName = 'free';

    static _requestRouts = {
        attachFile: '/match/file-upload',
        getSetting: {
            cities: '/match/cities',
            suppliers: '/match/suppliers'
        },
        getMarkingValues: '/match/fields',
        runMatch: '/match/sheet',
        getTableDownload: {
            found: '/match/found',
            unfound: '/match/not-found'
        },
        downloadMatch: '/match/to-pre-fact',
        getTemplates: '/template/list',
        setTemplate: {
            create: '/template/create',
            update: '/template/update'
        }
    };

    static _tableColumnsMarkingScheme = [
        // {
        //     label: "",
        //     prop: "infoError",
        //     isSlot: true,
        //     columnStyles: {width: '50px'}
        // },
        {
            label: "Выберите данные",
            prop: "markingValue",
            isSlot: true,
            columnStyles: {
                'width': '250px',
                'display': 'flex',
                'justify-content': 'center',
                'align-items': 'center',
            }
        },
        {
            label: "Заголовок из файла",
            prop: "titleFile",
            columnStyles: {width: '229px', overflow: 'hidden'}
        },
        {
            label: "Пример данных в столбце",
            prop: "dataColumn",
            columnStyles: {width: '229px', overflow: 'hidden'}
        }
    ];

    static _tableColumnsDownloadScheme = [
        {
            prop: 'supplier_entity',
            apiKey: 'supplier_entity',
            label: 'Юр. лицо аренды',
            isSlot: true,
        },
        {
            prop: 'city',
            apiKey: 'city',
            label: 'Город',
            isSlot: true,
        },
        {
            prop: 'address',
            apiKey: 'address',
            label: 'Адрес',
            isSlot: true,
        },
        {
            prop: 'supplier',
            apiKey: 'supplier',
            label: 'Подрядчик',
            isSlot: true,
        },
        {
            prop: 'gid',
            apiKey: 'gid',
            label: 'GID',
            isSlot: true,
        },
        {
            prop: 'kind',
            apiKey: 'kind',
            label: 'Вид',
            isSlot: true,
        },
        {
            prop: 'type',
            apiKey: 'type',
            label: 'Тип',
            isSlot: true,
        },
        {
            prop: 'format',
            apiKey: 'format',
            label: 'Формат',
            isSlot: true,
        },
        {
            prop: 'side',
            apiKey: 'side',
            label: 'Сторона',
            isSlot: true,
        },
        {
            prop: 'lat',
            apiKey: 'lat',
            label: 'Широта',
            isSlot: true,
        },
        {
            prop: 'lng',
            apiKey: 'lng',
            label: 'Долгота',
            isSlot: true,
        }
    ];

    static _settingsGetValueScheme = {
        cities: (item) => ({id: String(item?.id ?? ''), value: String(item?.name ?? '')}),
        suppliers: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')})
    };

    static _runMatchActionScheme = {
        approved: 'pre_fact',
        reconciliation: 'revise'
    }

    static async attachFile(file = null) {
        return this._attachFile(file);
    }

    static async _attachFile(file = null) {
        const reqBody = this._attachFileBefore(file);
        const reqHeader = {headers: {'Content-Type': 'multipart/form-data'}};

        try {
            const resBody = await ServiceApi.post(this._microserviceName, this._requestRouts.attachFile, reqBody, reqHeader);
            const {data = {}} = resBody;
            const {data: fileData = {}} = data;
            const fileDataAfter = this._attachFileAfter(fileData, file);

            return fileDataAfter;
        } catch (error) {
            console.log(error);
            const errorMsg = this._attachFileError(error);

            throw new Error(errorMsg);
        }
    }

    static _attachFileBefore(file = null) {
        let formData = new FormData();
        formData.append('file', file);

        return formData;
    }

    static _attachFileAfter(fileData = {}, file = null) {
        const {data: {meta: fileBody = []} = {}, file_id: fileId = ''} = fileData;
        const fileBodyAfter = fileBody.reduce((fileBodyAfter, fileBodyItem) => {
            const {name: tabListItemName = '', index: tabListItemId = '', columns: tableTitleFile = [], rows = []} = fileBodyItem;
            const tableDataColumn = rows[0] ?? [];

            const tabListItem = {id: String(tabListItemId), name: String(tabListItemName)};
            fileBodyAfter.tabList.push(tabListItem);

            const tabListItemRows = tableTitleFile.reduce((tabListItemRows, _, index) => {
                const titleFile = String(tableTitleFile[index]?.value ?? '');
                const dataColumn = String(tableDataColumn[index]?.value ?? '');

                if(titleFile !== '' || dataColumn !== '') {
                    const tabListItemRow = {
                        id: String(index),
                        markingValue: [],
                        infoError: false,
                        titleFile: String(tableTitleFile[index]?.value ?? ''),
                        dataColumn: String(tableDataColumn[index]?.value ?? '')
                    };
                    tabListItemRows.push(tabListItemRow);
                }

                return tabListItemRows;
            }, []);
            fileBodyAfter.tableRows = {
                ...fileBodyAfter.tableRows,
                [tabListItemId]: [...tabListItemRows]
            };

            return fileBodyAfter;
        }, {tabList: [], tableRows: {}});

        const fileDataAfter = {
            fileId: String(fileId),
            file,
            tabIdActive: String(fileBodyAfter.tabList[0]?.id ?? ''),
            tableColumns: [...this._tableColumnsMarkingScheme],
            ...fileBodyAfter
        };

        return fileDataAfter;
    }

    static _attachFileError(error) {
        const {response = {}} = error;
        const {data = {}} = response;
        const {data: dataError = {}} = data;
        const {file = []} = dataError;

        return file[0] ?? '';
    }

    static async getMarkingValues(activeTabId) {
        return this._getMarkingValues(activeTabId);
    }

    static async _getMarkingValues(activeTabId) {
        try {
            const resBody = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getMarkingValues + "?action=" + this._runMatchActionScheme[activeTabId]
            );
            const {data = {}} = resBody;
            const {data: markingValuesData = {}} = data;
            const markingValuesDataAfter = this._getMarkingValuesAfter(markingValuesData);

            return markingValuesDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getMarkingValuesAfter(markingValuesData = {}) {
        const {side: simpleItems = {}, price: multiItems = []} = markingValuesData;
        const markingValuesMap = {
            simple: simpleItems,
            multi: multiItems
        };
        const markingValues = Object.keys(markingValuesMap).reduce((markingValues, markingValueType) => {
            const markingValuesByType = markingValuesMap[markingValueType];
            const markingValuesAfter = markingValueType === 'multi' ? this._getMarkingValuesMultiAfter(markingValuesByType) : this._getMarkingValuesSimpleAfter(markingValuesByType);
            markingValues.push(...markingValuesAfter);

            return markingValues;
        }, []);

        return markingValues;
    }

    static _getMarkingValuesSimpleAfter(markingValuesByType = {}) {
        const markingValuesAfter = Object.keys(markingValuesByType).map(markingValueKey => {
            const markingValue = {
                id: String(markingValueKey),
                value: String(markingValuesByType[markingValueKey] ?? ''),
                type: 'simple'
            };
            return markingValue;
        });

        return markingValuesAfter;
    }

    static _getMarkingValuesMultiAfter(markingValuesByType = []) {
        const markingValuesAfter = markingValuesByType.map(markingValueByType => {
            const markingValue = {
                id: String(markingValueByType?.key ?? ''),
                value: String(markingValueByType?.label ?? ''),
                type: 'multi',
                dates: [],
                groupKey: String(markingValueByType?.group?? ''),
                groupLabel: String(markingValueByType?.group_label ?? '')
            };

            return markingValue;
        });

        return markingValuesAfter;
    }

    static async getSettings(settingsIds = []) {
        return this._getSettings(settingsIds);
    }

    static async _getSettings(settingsIds = []) {
        const requestPackage = settingsIds.map(settingId => this.getSetting(settingId, 1, ''));
        try {
            const settingsData = await Promise.all(requestPackage);

            return settingsData;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static async getSetting(settingId = '', page = 1, search = '') {
        return this._getSetting(settingId, page, search);
    }

    static async _getSetting(settingId = '', page = 1, search = '') {
        const queryParams = this._getSettingBefore(page, search);

        try {
            const resBody = await ServiceApi.get(this._microserviceName, this._requestRouts.getSetting[settingId] ?? '', queryParams);
            const {data = {}} = resBody;
            const {data: settingData = {}} = data;
            const settingDataAfter = this._getSettingAfter(settingId, page, settingData);

            return settingDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getSettingBefore(page = 1, search = '') {
        const queryParams = {
            'page': parseInt(page),
            'per-page': 20,
            'queryWord': String(search)
        };

        return queryParams;
    }

    static _getSettingAfter(settingId = '', page = 1, settingData = {}) {
        const {items: settingItems = [], totalCount = 0} = settingData;
        const settingsGetValues = this._settingsGetValueScheme[settingId] ?? (() => {});
        const settingItemsAfter = settingItems.map(settingItem => settingsGetValues(settingItem));
        const settingDataAfter = {
            id: String(settingId),
            items: settingItemsAfter,
            totalCount: parseInt(totalCount),
            page: parseInt(page)
        };

        return settingDataAfter;
    }

    static async runMatch(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        return this._runMatch(fileId, tabIdActiveMarking, tabIdActiveAttach, rowsMarkingSelected, settingsListIdsActive, startRowIndex);
    }

    static async _runMatch(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        const reqBody = this._runMatchBefore(fileId, tabIdActiveMarking, tabIdActiveAttach, rowsMarkingSelected, settingsListIdsActive, startRowIndex);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.runMatch, reqBody);
        } catch (error) {
            console.log(error);
            const errorMsg = this._runMatchError(error);
            throw new Error(errorMsg);
        }
    }

    static _runMatchBefore(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        const columnMap = this._getColumnMap(rowsMarkingSelected);
        const settingsMap = this._getSettingsMap(settingsListIdsActive);

        const reqBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(tabIdActiveMarking),
            start_row_index: parseInt(startRowIndex),
            column_map: columnMap,
            action: String(this._runMatchActionScheme[tabIdActiveAttach] ?? ''),
            city_id: String(settingsMap?.cities ?? ''),
            supplier_id: String(settingsMap?.suppliers ?? '')
        };

        return reqBody;
    }

    static _runMatchError(error) {
        const {response = {}} = error;
        const {data = {}} = response;
        const {data: dataError = {}} = data;
        const errors = Object.keys(dataError).reduce((errors, errorKey) => {
            const errorsArr = dataError[errorKey] ?? [];
            errors.push(...errorsArr);
            return errors;
        }, []);

        return errors.join(', ');
    }

    static _getColumnMap(rowsMarkingSelected = []) {
        const columnMap = rowsMarkingSelected.reduce((columnMap, rowMarkingSelected) => {
            let columnMapItems = [];
            const {rowId = '', markingValueId = '', dates = [], type = ''} = rowMarkingSelected;

            if (type === 'simple') {
                columnMapItems.push({
                    name: String(markingValueId),
                    index: String(rowId)
                });
            }
            else if (type === 'multi') {
                const markingValueIdArr = String(markingValueId).split('|');
                const markingValueIdStart = markingValueIdArr[0] ?? '';
                const markingValueIdEnd = markingValueIdArr[1] ?? '';
                const columnMapNames = dates.map(date => {
                    const markingValueIdBefore = `${markingValueIdStart}|${date}`;
                    return markingValueIdEnd === '' ? markingValueIdBefore : `${markingValueIdBefore}|${markingValueIdEnd}`;
                });
                columnMapItems.push(...columnMapNames.map(columnMapName => ({
                    name: String(columnMapName),
                    index: String(rowId)
                })));
            }

            columnMap.push(...columnMapItems);
            return columnMap;
        }, []);

        return columnMap;
    }

    static _getSettingsMap(settingsListIdsActive = []) {
        const settingsMap = settingsListIdsActive.reduce((settingsMap, setting) => {
            settingsMap[setting?.id] = String(setting?.listIdActive);
            return settingsMap;
        }, {});

        return settingsMap;
    }

    static async getTableDownload(tableKeys = [], fileId = '', tabIdActiveMarking = '') {
        return this._getTableDownload(tableKeys, fileId, tabIdActiveMarking);
    }

    static async _getTableDownload(tableKeys = [], fileId = '', tabIdActiveMarking = '') {
        const requestPackage = tableKeys.map(tableKey => this.getTableRowsDownload(tableKey, fileId, tabIdActiveMarking, 1));
        try {
            const tableData = await Promise.all(requestPackage);
            const tableDataAfter = this._getTableDownloadAfter(tableData);

            return tableDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTableDownloadAfter(tableData = []) {
        const tableDataAfter = tableData.reduce((tableDataAfter, tableDataItem) => {
            const {tableKey = '', tableRows = [], tableRowsTotalCount = 0} = tableDataItem;

            tableDataAfter.tableRows[tableKey] = tableRows;
            tableDataAfter.tableRowsTotalCount[tableKey] = tableRowsTotalCount;

            return tableDataAfter;
        }, {tableRows: {}, tableRowsTotalCount: {}});

        return {tableColumns: this._tableColumnsDownloadScheme, ...tableDataAfter};
    }

    static async getTableRowsDownload(tableKey = '', fileId = '', tabIdActiveMarking = '', page = 1) {
        return this._getTableRowsDownload(tableKey, fileId, tabIdActiveMarking, page);
    }

    static async _getTableRowsDownload(tableKey = '', fileId = '', tabIdActiveMarking = '', page = 1) {
        const queryParams = this._getTableRowsDownloadBefore(tabIdActiveMarking, page);

        try {
            const resBody = await ServiceApi.get(this._microserviceName, `${this._requestRouts.getTableDownload[tableKey] ?? ''}/${fileId}`, queryParams);
            const {data = {}} = resBody;
            const {data: tableRowsData = {}} = data;
            const tableRowsDataAfter = this._getTableRowsDownloadAfter(tableKey, tableRowsData);

            return tableRowsDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTableRowsDownloadBefore(tabIdActiveMarking = '', page = 1) {
        const queryParams = {
            'sheet_index': parseInt(tabIdActiveMarking),
            'page': parseInt(page),
            'per-page': 2000
        };

        return queryParams;
    }

    static _getTableRowsDownloadAfter(tableKey = '', tableRowsData = {}) {
        const rowKeys = this._tableColumnsDownloadScheme.map(tableColumnDownloadScheme => String(tableColumnDownloadScheme?.apiKey ?? ''));
        const {items: rows = [], totalCount = 0} = tableRowsData;
        const tableRows = rows.map(row => {
            const {row: rowData = {}, row_index: rowIndex = '', errors = {}} = row;
            const tableRow = rowKeys.reduce((tableRow, rowKey) => {
                tableRow[rowKey] = {
                    value: String(rowData[rowKey] ?? ''),
                    error: Array.from(errors[rowKey] ?? []).join(', ')
                };

                return tableRow;
            }, {id: String(rowIndex)});

            return tableRow;
        });

        return {tableRows, tableKey, tableRowsTotalCount: parseInt(totalCount)};
    }

    static async downloadMatch(fileId = '', tabIdActiveMarking = '') {
        return this._downloadMatch(fileId, tabIdActiveMarking);
    }

    static async _downloadMatch(fileId = '', tabIdActiveMarking = '') {
        const reqBody = this._downloadMatchBefore(fileId, tabIdActiveMarking);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.downloadMatch, reqBody);

            return true;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }


    }

    static _downloadMatchBefore(fileId = '', tabIdActiveMarking = '') {
        const reqBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(tabIdActiveMarking),
            is_overwrite: 1
        };

        return reqBody;
    }

    static async getTemplates(templateType = '', markingValues = []) {
        return this._getTemplates(templateType, markingValues);
    }

    static async _getTemplates(templateType = '', markingValues = []) {
        try {
            const route = this._getTemplatesBefore(templateType);

            const res = await ServiceApi.get(this._microserviceName, route);
            const {data = {}} = res;
            const {data: templatesData = []} = data;
            const templates = this._getTemplatesAfter(templatesData, markingValues);
            return templates;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTemplatesBefore(templateType = '') {
        return `${this._requestRouts.getTemplates}-${String(templateType)}`;
    }

    static _getTemplatesAfter(templates = [], markingValues = []) {
        return templates.map(template => {
            const {
                id = '',
                data = {}
            } = template;
            const {
                column_map: columnMap = [],
                city_id: cityId = '',
                supplier_id: supplierId = ''
            } = data;

            const rowsMarkingValue = columnMap.reduce((rowsMarkingValue, columnMapItem) => {
                const {index: rowId = '', name = ''} = columnMapItem;
                const nameArr = name.split('|');

                const markingValueType = nameArr.length > 1 ? 'multi' : 'simple';
                const markingValueId = nameArr.length === 3 ? `${nameArr[0]}|${nameArr[2]}` : `${nameArr[0] ?? ''}`;
                const markingValueDate = String(nameArr[1] ?? '');
                const markingValueExtra = markingValues.find(markingValue => String(markingValue?.id ?? '') === markingValueId) ?? {};
                const {value: markingValueName = ''} = markingValueExtra;

                let rowMarkingValue = rowsMarkingValue[rowId] ?? [];
                const index = rowMarkingValue.findIndex(rowMarkingValueItem => String(rowMarkingValueItem?.id ?? '') === markingValueId);
                if (index !== -1) {
                    const dates = rowMarkingValue[index]?.dates ?? [];
                    rowMarkingValue.splice(index, 1, {...rowMarkingValue[index], dates: [...dates, markingValueDate]});
                } else {
                    let rowMarkingValueAfter = {
                        id: markingValueId,
                        type: markingValueType,
                        value: String(markingValueName),
                    };
                    if (markingValueType === 'multi')
                        rowMarkingValueAfter.dates = [markingValueDate];
                    rowMarkingValue.push(rowMarkingValueAfter);
                }
                rowsMarkingValue[rowId] = rowMarkingValue;

                return rowsMarkingValue;
            }, {});

            const settingsValue = {
                cities: String(cityId),
                suppliers: String(supplierId)
            };

            const templateAfter = {
                id: String(id),
                extraData: {...template},
                rowsMarkingValue,
                settingsValue
            };

            return templateAfter;
        });
    }

    static async setTemplate({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0, markingValues = [], actionType = ''}) {
        return this._setTemplate({templateName, templateAdditingParams, rowsMarkingSelected, settingsListIdsActive, startRowIndex, markingValues, actionType});
    }

    static async _setTemplate({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0, markingValues = [], actionType = ''}) {
        const requestTypes = {create: 'post', update: 'put'};
        const reqBody = this._setTemplateBefore({templateName, templateAdditingParams, rowsMarkingSelected, settingsListIdsActive, startRowIndex});

        try {
            const res = await ServiceApi[requestTypes[actionType] ?? ''](this._microserviceName, this._requestRouts.setTemplate[actionType] ?? '', reqBody);
            const {data = {}} = res;
            const {data: templateData = {}} = data;
            const template = this._getTemplatesAfter([templateData], markingValues)[0] ?? {};
            return template;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }

    }

    static _setTemplateBefore({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0}) {
        const columnMap = this._getColumnMap(rowsMarkingSelected);
        const settingsMap = this._getSettingsMap(settingsListIdsActive);

        const reqBody = {
            ...templateAdditingParams,
            name: String(templateName),
            data: {
                start_row_index: parseInt(startRowIndex),
                column_map: columnMap,
                city_id: String(settingsMap?.cities ?? ''),
                supplier_id: String(settingsMap?.suppliers ?? '')
            }
        };

        return reqBody;
    }
}
